import React, { useState, useEffect, forwardRef } from 'react'
import { Upload } from 'antd'
import ImgCrop from 'antd-img-crop'
import { useImage } from '../../hooks'

const UploadImage = forwardRef(
  ({ name, value, onImageChange, folder, setError, noCrop }, ref) => {
    // Here goes the content of our component
    const [fileList, setFileList] = useState([])
    const { upload } = useImage()

    useEffect(() => {
      if (value) {
        setFileList([
          {
            uid: '-1',
            name: value,
            status: 'done',
            url: value,
          },
        ])
      }
    }, [value])

    const onChange = ({ fileList: newFileList }) => {
      setFileList(newFileList)
      if (newFileList.length <= 0) {
        onImageChange(name, '')
      } else {
        setError(name, undefined)
      }
    }

    const onPreview = async (file) => {
      let src = file.url
      if (!src) {
        src = await new Promise((resolve) => {
          const reader = new FileReader()
          reader.readAsDataURL(file.originFileObj)
          reader.onload = () => resolve(reader.result)
        })
      }
      const image = new Image()
      image.src = src
      const imgWindow = window.open(src)
      imgWindow.document.write(image.outerHTML)
    }

    const props = {
      customRequest({ file, onSuccess }) {
        upload(file, file.name, folder, (photoURL) => {
          setFileList([
            {
              uid: '-1',
              name: file.name,
              status: 'done',
              url: photoURL,
            },
          ])
          onImageChange(name, photoURL)
          onSuccess('ok', file)
        })

        return {
          abort() {
            console.log('upload progress is aborted.')
          },
        }
      },
    }

    return noCrop ? (
      <Upload
        {...props}
        listType="picture-card"
        fileList={fileList}
        onChange={onChange}
        onPreview={onPreview}
        ref={ref}
      >
        {fileList.length < 1 && '+ Upload'}
      </Upload>
    ) : (
      <ImgCrop rotate quality={0.75} aspect={16 / 9}>
        <Upload
          {...props}
          listType="picture-card"
          fileList={fileList}
          onChange={onChange}
          onPreview={onPreview}
        >
          {fileList.length < 1 && '+ Upload'}
        </Upload>
      </ImgCrop>
    )
  }
)

export default UploadImage
