import { useState } from 'react'
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'
import { storage } from '../config'
import { useAppContext } from '../context'

function useImage() {
  const [uploading, setUploading] = useState(false)
  const { setError } = useAppContext()
  const [progress, setProgress] = useState(0)

  function upload(file, filename, folder, callback) {
    setUploading(true)
    const storageRef = ref(
      storage,
      `${folder}/${new Date().getTime()}-${filename}`
    )
    const uploadTask = uploadBytesResumable(storageRef, file)

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        setProgress(progress)
      },
      (error) => {
        setError(error)
        setUploading(false)
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) =>
          callback(downloadURL)
        )
        setUploading(false)
      }
    )
  }

  return { upload, uploading, progress }
}

export { useImage }
