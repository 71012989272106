import React from 'react'

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from 'recharts'

function Sales({ data }) {
  return (
    <div>
      <h1 style={{ marginLeft: '32px', fontSize: '24px' }}>Subscriber</h1>
      <ResponsiveContainer minHeight={360}>
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          barSize={50}
        >
          <XAxis
            dataKey="name"
            style={{
              fontSize: '1rem',
            }}
            margin={10}
          />
          <YAxis
            type="number"
            domain={[(dataMin) => 0, (dataMax) => Math.abs(dataMax) + 3]}
          />
          <Tooltip />
          <Bar dataKey="subscribers" fill="#FFA3AC" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}

export default Sales
