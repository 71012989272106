/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { Modal, Form, Input, InputNumber } from 'antd'
import * as yup from 'yup'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import UploadImage from '../../../Components/Upload/image'

const validationSchema = () =>
  yup.object().shape({
    header: yup.string().required('Title is Required'),
    subheader: yup.string().required('Sub Title is Required'),
    price: yup.number().required(),
    description: yup.string().required(),
    video: yup.string(),
    thumbnail: yup.string().required(),
    url: yup.string().required(),
    minutes: yup.number().required(),
    order: yup.number().required(),
  })

const ClassForm = ({ data, visible, onClose, onCreate, onEdit }) => {
  const { setValue, handleSubmit, formState, control, reset, setError } =
    useForm({
      resolver: yupResolver(validationSchema()),
    })

  const errors = formState.errors

  const onSubmit = async (values) => {
    if (data?.id) {
      onEdit(data?.id, values)
    } else {
      onCreate(values)
    }
  }

  useEffect(() => {
    if (data) {
      setData(data)
    } else {
      reset()
    }
  }, [data])

  function setData(data) {
    for (var key in data) {
      if (key !== 'id') {
        setValue(key, data[key])
      }
    }
  }

  return (
    <Modal
      width="40vw"
      visible={visible}
      title={data?.id ? 'Edit Online Class' : 'Create Online Class'}
      okText={'Save'}
      cancelText={'Close'}
      onCancel={() => {
        reset()
        onClose()
      }}
      onOk={handleSubmit(onSubmit)}
    >
      <Form layout="vertical">
        <Controller
          name="header"
          control={control}
          render={({ field }) => (
            <Form.Item
              label="Title"
              validateStatus={errors.header && 'error'}
              help={errors.header && errors.header.message}
            >
              <Input {...field} />
            </Form.Item>
          )}
        />
        <Controller
          name="subheader"
          control={control}
          render={({ field }) => (
            <Form.Item
              label="Sub Header"
              validateStatus={errors.subheader && 'error'}
              help={errors.subheader && errors.subheader.message}
            >
              <Input {...field} />
            </Form.Item>
          )}
        />
        <Controller
          name="url"
          control={control}
          render={({ field }) => (
            <Form.Item
              label="Url"
              validateStatus={errors.url && 'error'}
              help={errors.url && errors.url.message}
            >
              <Input {...field} />
            </Form.Item>
          )}
        />
        <Controller
          name="price"
          control={control}
          render={({ field }) => (
            <Form.Item
              label="Price"
              validateStatus={errors.price && 'error'}
              help={errors.price && errors.price.message}
            >
              <InputNumber
                style={{ width: '100%' }}
                addonafter="฿"
                {...field}
              />
            </Form.Item>
          )}
        />
        <Controller
          name="description"
          control={control}
          render={({ field }) => (
            <Form.Item
              label="Descriptions"
              validateStatus={errors.description && 'error'}
              help={errors.description && errors.description.message}
            >
              <Input.TextArea rows={8} {...field} />
            </Form.Item>
          )}
        />
        <Controller
          name="thumbnail"
          control={control}
          render={({ field }) => (
            <Form.Item
              label="Thumbnail"
              validateStatus={errors.thumbnail && 'error'}
              help={errors.thumbnail && errors.thumbnail.message}
            >
              <UploadImage
                {...field}
                onImageChange={setValue}
                folder={`class/thumbnail`}
                setError={setError}
              />
            </Form.Item>
          )}
        />

        <Controller
          name="minutes"
          control={control}
          render={({ field }) => (
            <Form.Item
              label="Minutes"
              validateStatus={errors.minutes && 'error'}
              help={errors.minutes && errors.minutes.message}
            >
              <InputNumber style={{ width: '100%' }} {...field} />
            </Form.Item>
          )}
        />
        <Controller
          name="order"
          control={control}
          render={({ field }) => (
            <Form.Item
              label="Order"
              validateStatus={errors.order && 'error'}
              help={errors.order && errors.order.message}
            >
              <InputNumber style={{ width: '100%' }} {...field} />
            </Form.Item>
          )}
        />
      </Form>
    </Modal>
  )
}

export default ClassForm
