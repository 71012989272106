/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'

import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
} from 'firebase/auth'
import { getDoc, doc } from 'firebase/firestore'
import { useAppContext } from '../context'
import { SUCCESS, ERROR, db } from '../config'

const auth = getAuth()

function useAuth() {
  const { user, setUser, setError, setMessage } = useAppContext()
  const [loading, setLoading] = useState(false)

  // Get user login state
  useEffect(() => {
    setLoading(true)

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        getProfile(user)
      } else {
        setUser(null)
        setLoading(false)
      }
    })

    return () => unsubscribe()
  }, [])

  async function getProfile(user) {
    const { uid } = user
    try {
      setLoading(true)
      const profileRef = doc(db, `profile`, uid)
      const profileSnapshot = await getDoc(profileRef)
      const profile = profileSnapshot.data()

      if (profile.isAdmin) {
        setUser({ ...user, profile: profile })
      } else {
        auth.signOut()
        setUser(null)
      }

      setLoading(false)
    } catch (error) {
      console.log(error)
      setError(error)
    }
  }

  // Login with email and password
  async function login(email, password) {
    setLoading(true)

    signInWithEmailAndPassword(auth, email, password)
      .then(async (credential) => {
        const profileRef = doc(db, `profile`, credential.user.uid)
        const profileSnapshot = await getDoc(profileRef)
        const profile = profileSnapshot.data()

        if (profile.isAdmin) {
          setUser({ ...auth?.currentUser, profile: profile })
          setMessage({ message: 'Login SUCCESS', type: SUCCESS })
        } else {
          setMessage({ message: 'Unauthorized', type: ERROR })
          auth.signOut()
          setUser(null)
        }
      })
      .catch((error) => {
        setError(error)
        setMessage({ message: error.message, type: ERROR })

        setLoading(false)
      })
  }

  // Sigup with Email and Password
  async function signupWithEmailAndPassword(email, password) {
    setLoading(true)

    createUserWithEmailAndPassword(auth, email, password)
      .then(() => {
        setLoading(false)
        setMessage({ type: SUCCESS, message: 'SignUp successful' })
      })
      .catch((error) => {
        setError(error)
        setMessage({ message: error.message, type: ERROR })

        setLoading(false)
      })
  }

  async function resetPassword(email, password) {
    setLoading(true)

    sendPasswordResetEmail(auth, email)
      .then(() => {
        setMessage({ type: SUCCESS, message: 'Password reset email sent!' })
        setLoading(false)
      })
      .catch((error) => {
        setError(error)
        setMessage({ message: error.message, type: ERROR })
        setLoading(false)
        // ..
      })
  }

  // Login with provider
  async function signInWithFacebook() {
    const provider = new auth.FacebookAuthProvider()

    try {
      setLoading(true)
      const user = await auth.signInWithPopup(provider)

      setMessage({ type: SUCCESS, message: 'Log in successful' })
      return user
    } catch (error) {
      setError(error)
      setMessage({ ...error, type: ERROR })
    } finally {
      setLoading(false)
    }
  }
  // Logout
  async function logout() {
    try {
      setLoading(true)

      await auth.signOut()

      setUser(null)

      setLoading(false)
      setMessage({ type: SUCCESS, message: 'Log out successful' })
    } catch (error) {
      setError(error)
      setMessage({ ...error, type: ERROR })

      setLoading(false)
    }
  }

  // Update password
  async function updatePassword(currentPassword, newPassword) {
    try {
      setLoading(true)

      const user = auth.currentUser

      if (user !== null) {
        const credential = auth.EmailAuthProvider.credential(
          user.email || '',
          currentPassword
        )

        await user.reauthenticateWithCredential(credential)

        await user.updatePassword(newPassword)

        setMessage({ type: SUCCESS, message: 'Update password successful' })
      }

      setLoading(false)
    } catch (error) {
      setError(error)
      setMessage({ ...error, type: ERROR })

      setLoading(false)
    }
  }

  // Update email
  async function updateEmail(email, password) {
    try {
      setLoading(true)

      const user = auth.currentUser

      if (user !== null) {
        const credential = auth.EmailAuthProvider.credential(
          user.email || '',
          password
        )

        await user.reauthenticateWithCredential(credential)

        await user.updateEmail(email)

        setMessage({ type: SUCCESS, message: 'Update email successful' })
      }

      setLoading(false)
    } catch (error) {
      setError(error)
      setMessage({ ...error, type: ERROR })

      setLoading(false)
    }
  }

  // Update user profile
  async function updateProfile(profile) {
    try {
      setLoading(true)

      const user = auth.currentUser

      if (user !== null) {
        await user.updateProfile(profile)

        setMessage({ type: SUCCESS, message: 'Update profile successful' })
      }

      setLoading(false)
    } catch (error) {
      setError(error)
      setMessage({ ...error, type: ERROR })

      setLoading(false)
    }
  }

  return {
    login,
    signupWithEmailAndPassword,
    signInWithFacebook,
    logout,
    updatePassword,
    updateEmail,
    updateProfile,
    resetPassword,
    user,
    isLogin: !!user,
    loading,
  }
}

export { useAuth }
