import { Link } from 'react-router-dom'
import { Layout, Menu, Dropdown, Avatar } from 'antd'
import { BiPowerOff, BiUser } from 'react-icons/bi'
import { UserOutlined } from '@ant-design/icons'
import { signOut } from 'firebase/auth'
import { auth } from '../../config/firebase'
import { useHistory } from 'react-router-dom'
import Logo from '../../littlerose-logo-nav.png'

const { Header } = Layout

function Navbar({ email }) {
  const history = useHistory()

  function onClickMenu(event) {
    switch (event.key) {
      case 'logout':
        signOut(auth).then(() => {
          history.push('/')
        })
        break
      default:
        console.log('N/A')
        break
    }
  }

  return (
    <Header
      style={{
        position: 'fixed',
        backgroundColor: 'rgb(255, 255, 255)',
        zIndex: 2,
        width: '-webkit-fill-available',
        display: 'flex',
        justifyContent: 'space-between',
        boxShadow: '0 1px 2px 0 rgb(0 0 0 / 20%)',
      }}
    >
      <div>
        <Link to="/">
          <img
            src={Logo}
            alt="logo"
            style={{ width: 110, height: 30, marginRight: 10 }}
          />
        </Link>
      </div>
      <div>
        <Dropdown
          overlay={
            <Menu onClick={onClickMenu}>
              <Menu.Item icon={<BiUser />} key="profile" disabled>
                <span>{email}</span>
              </Menu.Item>
              <Menu.Item icon={<BiPowerOff />} key="logout">
                Log out
              </Menu.Item>
            </Menu>
          }
        >
          <Avatar icon={<UserOutlined />} style={{ cursor: 'pointer' }} />
        </Dropdown>
      </div>
    </Header>
  )
}

export default Navbar
