/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import MainLayout from '../../Components/MainLayout'
import { Col, Row, Card, Spin } from 'antd'
import { useDashBoard } from '../../hooks'

import NumberCard from './components/numberCard'
import Sales from './components/sales'

function Dashboard({ user }) {
  const { loading, getDashBoard } = useDashBoard()
  const [data, setData] = useState()
  const [numbers, setNumbers] = useState()

  useEffect(() => {
    if (user?.uid) {
      fetchData(user.uid)
    }
  }, [user])

  const fetchData = async (uid) => {
    const result = await getDashBoard(uid)
    setData(result)
    setNumbers([
      {
        icon: 'online-class',
        color: '#FFA3AC',
        title: 'Online Class',
        number: result?.courseCount,
      },
      {
        icon: 'team',
        color: 'blue',
        title: 'Acounts',
        number: result?.profileCount,
      },
      {
        icon: 'lesson',
        color: 'red',
        title: 'Lessons',
        number: result?.lessonsCount,
      },
    ])
  }

  const numberCards =
    numbers &&
    numbers.map((item, key) => (
      <Col key={key} lg={6} md={12}>
        <NumberCard {...item} />
      </Col>
    ))

  return (
    <MainLayout user={user}>
      <div className="site-card-wrapper">
        <Row gutter={24}>{numberCards}</Row>
        <Col>
          <Card
            bordered={false}
            style={{
              padding: '24px 36px 24px 0',
            }}
          >
            {!loading && data ? (
              <Sales data={data?.courseDocsWithUsers} />
            ) : (
              <div
                className="site-card-wrapper"
                style={{
                  height: '50vh',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Spin size="large" />
              </div>
            )}
          </Card>
        </Col>
      </div>
    </MainLayout>
  )
}

export default Dashboard
