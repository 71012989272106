/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from 'react'
import MainLayout from '../../Components/MainLayout'
import {
  Table,
  Space,
  Breadcrumb,
  Card,
  Button,
  Avatar,
  Input,
  Typography,
  Col,
} from 'antd'
import { Link } from 'react-router-dom'
import { PlusOutlined } from '@ant-design/icons'
import { useAccount, useLesson } from '../../hooks'
import AccountForm from './AccountForm'
import CourseList from './CourseList'
import { UserOutlined } from '@ant-design/icons'

const { Text } = Typography

function Account({ user }) {
  const {
    getAccounts,
    addAccount,
    updateAccount,
    searchAccount,
    loading,
    accounts,
  } = useAccount()
  const {
    getCourses,
    subscribeCourse,
    unsubscribeCourse,
    loading: coursesLoading,
  } = useLesson()
  const [courses, setCourses] = useState([])

  const [accountDialog, setAccountDialog] = useState({
    open: false,
    data: null,
  })
  const [courseDialog, setCourseDialog] = useState({
    open: false,
    data: null,
  })

  useEffect(() => {
    if (user?.uid) {
      fetchData(user.uid)
    }
  }, [user])

  const fetchData = async (uid) => {
    await getAccounts(uid)
    getCourseList()
  }

  const getCourseList = async () => {
    const courses = await getCourses()
    setCourses(courses)
  }

  const onCreate = async (data) => {
    try {
      const result = await addAccount(data)
      if (result) {
        fetchData(user.uid)
      }
      setAccountDialog({
        open: false,
        data: null,
      })
    } catch (error) {
      console.error(error)
    }
  }

  const onEdit = async (id, data) => {
    const result = await updateAccount(id, data)
    if (result === 'success') {
      fetchData(user.uid)
      setAccountDialog({
        open: false,
        data: null,
      })
    }
  }

  const onChangeSubscription = async (uid, courseId, subscribed) => {
    if (subscribed) {
      const result = await unsubscribeCourse(uid, courseId)
      if (result === 'success') {
        fetchData(user.uid)
      }
    } else {
      const result = await subscribeCourse(uid, courseId)
      if (result === 'success') {
        fetchData(user.uid)
      }
    }
  }

  const handelCustomSearch = (text) => {
    if (text !== '') {
      searchAccount(text)
    } else {
      fetchData(user.uid)
    }
  }

  const columns = [
    // {
    //   title: 'No.',
    //   dataIndex: 'no.',
    //   width: 50,
    //   key: 'no.',
    //   align: 'center',
    //   render: (data, row, index) => {
    //     return index + 1
    //   },
    // },
    {
      title: 'Avatar',
      key: 'photoURL',
      dataIndex: 'photoURL',
      align: 'center',
      render: (photoURL, row) =>
        photoURL ? (
          <Avatar src={photoURL} />
        ) : (
          <Avatar icon={<UserOutlined />}>{row.firstname}</Avatar>
        ),
    },

    {
      title: 'Email',
      key: 'email',
      dataIndex: 'email',
      align: 'center',
    },

    {
      title: 'First Name',
      key: 'firstname',
      dataIndex: 'firstname',
      align: 'center',
      render: (text) => (
        <Text style={{ width: 100 }} ellipsis={{ tooltip: text }}>
          {text}
        </Text>
      ),
    },

    {
      title: 'Last Name',
      dataIndex: 'lastname',
      key: 'lastname',
      align: 'center',
      render: (text) => (
        <Text style={{ width: 100 }} ellipsis={{ tooltip: text }}>
          {text}
        </Text>
      ),
    },
    {
      title: 'Telephone',
      key: 'phoneNumber',
      dataIndex: 'phoneNumber',
      align: 'right',
    },
    {
      title: 'Subscribed Courses',
      dataIndex: 'courses',
      key: 'courses',
      align: 'center',
      render: (courses, record) => (
        <Space size="middle">
          <Button
            type="link"
            onClick={() =>
              setCourseDialog({
                courses: courseDialog.courses,
                open: true,
                data: record,
              })
            }
          >
            {courses.length}
          </Button>
        </Space>
      ),
    },

    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <Button
            type="link"
            onClick={() =>
              setAccountDialog({
                open: true,
                data: record,
              })
            }
          >
            Edit
          </Button>
        </Space>
      ),
    },
  ]
  return (
    <MainLayout user={user}>
      <Breadcrumb style={{ marginBottom: 16 }}>
        <Breadcrumb.Item>
          <Link to="/">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Account</Breadcrumb.Item>
      </Breadcrumb>
      <Card
        title="Account List"
        extra={
          <Button
            icon={<PlusOutlined />}
            onClick={() => {
              setAccountDialog({
                open: true,
                data: null,
              })
            }}
          >
            New Account
          </Button>
        }
      >
        <Col lg={6}>
          <Input.Search
            placeholder="Name / Email"
            onSearch={(text) => handelCustomSearch(text)}
            style={{ marginBottom: 20 }}
          />
        </Col>

        <Table
          scroll={{ x: 'max-content' }}
          rowKey={(record) => `${record.id}`}
          columns={columns}
          dataSource={accounts}
          loading={loading}
        />
      </Card>
      {accountDialog.open && (
        <AccountForm
          visible={accountDialog.open}
          onClose={() =>
            setAccountDialog({
              open: false,
              data: null,
            })
          }
          onCreate={onCreate}
          data={accountDialog.data}
          onEdit={onEdit}
          loading={loading}
        />
      )}
      {courseDialog.open && (
        <CourseList
          visible={courseDialog.open}
          onClose={() =>
            setCourseDialog({
              open: false,
              data: null,
            })
          }
          data={courseDialog.data}
          courses={courses}
          onEdit={onChangeSubscription}
          loading={coursesLoading}
        />
      )}
    </MainLayout>
  )
}

export default Account
