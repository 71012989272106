/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from 'react'
import MainLayout from '../../Components/MainLayout'
import {
  Table,
  Space,
  Breadcrumb,
  Card,
  Button,
  Popconfirm,
  Tooltip,
  Input,
  Col,
} from 'antd'
import { Link } from 'react-router-dom'
import { PlusOutlined } from '@ant-design/icons'
import { useLesson } from '../../hooks'
import ClassForm from './ClassForm'

function OnlineClass({ user }) {
  const {
    getCourses,
    loading,
    deleteCourse,
    addCourse,
    updateCourse,
    seachCourses,
  } = useLesson()
  const [courses, setCourses] = useState([])
  const [classDialog, setClassDialog] = useState({
    open: false,
    data: null,
  })

  useEffect(() => {
    if (user?.uid) {
      fetchData(user.uid)
    }
  }, [user])

  const fetchData = async (uid) => {
    const result = await getCourses(uid)

    setCourses(result)
  }

  const onCreate = async (data) => {
    const result = await addCourse(data)
    if (result) {
      fetchData(user.uid)
      setClassDialog({
        open: false,
        data: null,
      })
    }
  }

  const onEdit = async (id, data) => {
    const result = await updateCourse(id, data)
    if (result === 'success') {
      fetchData(user.uid)
      setClassDialog({
        open: false,
        data: null,
      })
    }
  }

  const onDelete = async (id) => {
    const result = await deleteCourse(id)
    if (result === 'success') {
      fetchData(user.uid)
    }
  }

  const handelCustomSearch = async (text) => {
    if (text !== '') {
      const result = await seachCourses(text)
      setCourses(result)
    } else {
      fetchData(user.uid)
    }
  }

  const columns = [
    {
      title: 'Order',
      key: 'order',
      dataIndex: 'order',
    },
    {
      title: 'Name',
      dataIndex: 'header',
      key: 'header',
      render: (text, record) => (
        <Link to={`/onlineclass/${record?.id}`}>{text}</Link>
      ),
    },
    {
      title: 'SubHeader',
      dataIndex: 'subheader',
      key: 'subheader',
    },
    {
      title: 'Price',
      key: 'price',
      dataIndex: 'price',
      render: (text) =>
        text?.toLocaleString('th-TH', {
          style: 'currency',
          currency: 'THB',
          minimumFractionDigits: 0,
        }),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: '30%',
      onCell: () => {
        return {
          style: {
            whiteSpace: 'nowrap',
            maxWidth: 150,
          },
        }
      },
      render: (text) => (
        <Tooltip title={text}>
          <div style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
            {text}
          </div>
        </Tooltip>
      ),
    },

    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <Button
            type="link"
            onClick={() =>
              setClassDialog({
                open: true,
                data: record,
              })
            }
          >
            Edit
          </Button>
          <Popconfirm
            title="Are you sure to delete this Class?"
            onConfirm={() => onDelete(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link" href="#">
              Delete
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ]

  return (
    <MainLayout user={user}>
      <Breadcrumb style={{ marginBottom: 16 }}>
        <Breadcrumb.Item>
          <Link to="/">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Online Class</Breadcrumb.Item>
      </Breadcrumb>
      <Card
        title="Online Class List"
        extra={
          <Button
            icon={<PlusOutlined />}
            onClick={() => {
              setClassDialog({
                open: true,
                data: null,
              })
            }}
          >
            New Online Class
          </Button>
        }
      >
        <Col lg={6}>
          <Input.Search
            placeholder="Name / Email"
            onSearch={(text) => handelCustomSearch(text)}
            style={{ marginBottom: 20 }}
          />
        </Col>
        <Table
          scroll={{ x: 'max-content' }}
          // size='small'
          rowKey={(record) => `${record.id}`}
          columns={columns}
          dataSource={courses}
          loading={loading}
          // onChange={handleTableChange}
          // pagination={{
          //   position: 'bottom',
          //   defaultPageSize: 10,
          //   showSizeChanger: true,
          //   pageSizeOptions: ['10', '25', '50', '100'],
          //   total: pagination.total,
          //   current: pagination.currentPage,
          //   showTotal: (total, range) =>
          //     `${range[0]}-${range[1]} of ${total} items`,
          // }}
        />
      </Card>
      {classDialog.open && (
        <ClassForm
          visible={classDialog.open}
          onClose={() =>
            setClassDialog({
              open: false,
              data: null,
            })
          }
          onCreate={onCreate}
          data={classDialog.data}
          onEdit={onEdit}
        />
      )}
    </MainLayout>
  )
}

export default OnlineClass
