import { useHistory, useLocation } from 'react-router-dom'
import { Menu, Layout } from 'antd'
import { HomeOutlined, BookOutlined, UserOutlined } from '@ant-design/icons'

const { Sider } = Layout

function SideMenu({ collapsed }) {
  const { pathname } = useLocation()
  const history = useHistory()

  function menuClick(item) {
    history.push(item.key)
  }

  return (
    <Sider
      breakpoint="lg"
      collapsedWidth="0"
      style={{
        backgroundColor: '#FFF',
      }}
    >
      <Menu
        mode="inline"
        onClick={menuClick}
        defaultSelectedKeys={[pathname]}
        style={{
          backgroundColor: '#FFF',
          paddingTop: 65,
        }}
      >
        <Menu.Item key="/" icon={<HomeOutlined />}>
          Dashboard
        </Menu.Item>
        <Menu.Item key="/onlineclass" icon={<BookOutlined />}>
          Online Classes
        </Menu.Item>
        <Menu.Item key="/account" icon={<UserOutlined />}>
          Accounts
        </Menu.Item>
      </Menu>
    </Sider>
  )
}

export default SideMenu
