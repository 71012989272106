import { initializeApp } from 'firebase/app'
import { getFunctions } from 'firebase/functions'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'

const config = {
  apiKey: 'AIzaSyAycYA5YRqr9hyRunXmwodD_pCfgtmurdg',
  authDomain: 'littlerose-prod.firebaseapp.com',
  projectId: 'littlerose-prod',
  storageBucket: 'littlerose-prod.appspot.com',
  messagingSenderId: '961879683806',
  appId: '1:961879683806:web:55bd431af37789b804884f',
  measurementId: 'G-DJZJ4XHRJ0',
}

const firebaseApp = initializeApp(config)

const functions = getFunctions()
const auth = getAuth()

const db = getFirestore(firebaseApp)
const storage = getStorage(firebaseApp)

export { functions, auth, db, storage }
